import React, { memo, useEffect, useRef, useState } from 'react'

//routte segment
import RouteSegments from '../../Routes/RouteSegment'
import $ from 'jquery'
//components
import LoginForm from '../LoginForm/Form'
import Constants, { EventName, PageName } from "../../Constants/constants";
import AboutusLogo from '../../Images/AboutUsLogo.png'
import KMLogo from "../../Images/KM-logo_1.webp"
// import KMLogo from "../../Images/KM logo@2x.png"
// import KMLogo from "../../Images/KM-logo_2x.webp"
import AppStoreIcon from "../../Images/appstore.png"
import GPlayIcon from "../../Images/googleplay.png"
import HelpIcon from "../../Images/Group 537.svg"
import usePageChange, { useAnalytics } from '../../Hooks/usePageChange';
import FamilyfriedloginPopup from '../Kalyanlite/Popup/FamilyfriedloginPopup';
import CommunityTag from './CommunityTag';
import WhatsAppIcon from "../../Components/Kalyanlite/assets/Images/Squarewhatsapp.png"
import MailIcon from "../../Components/Kalyanlite/assets/Images/MailIcon.png"
import { useLocation } from 'react-router-dom';


export default function Header(props) {

    usePageChange(PageName.Home_page_Home_Page);
    const { onClickTrack } = useAnalytics()
    const [showFamilyPopup, setShowFamilyPopup] = useState(false)
    const location = useLocation()
    const isNRIPage = location.pathname === RouteSegments.LOGIN_FROM_NRI
    const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

    const onFamilyFriendPopupClick = () => {
        setShowFamilyPopup(true)
        onClickTrack(EventName.Home_page_Signin_Login_as_Family_Friend_Button)
    }


    return (
        <>{showFamilyPopup &&

            <FamilyfriedloginPopup
                close={() => setShowFamilyPopup(false)}

                title={"FAMILY/FRIEND LOGIN"} />
        }
            <div className={props.menu === "mobilebasic" ? "sticky top-0 z-10" : props.type === "mobileBasicHeader" ? "sticky top-0 z-10" : 'sticky top-0 z-10 border-b-[0.3rem] border-[#D23C27]'} >
                {/* <InjectScript script={scriptToInject} /> */}
                <CommunityTag className='bg-white' />
                <header className={props.menu === "mobilebasic" ? "w-full bg-[#D23C27]" : " w-full bg-white md:h-[10vh] lg:h-full"}>
                    {props?.isFrom == "NRI" ?
                        <div className="bg-[#D10A11] flex items-center gap-2 md:gap-4 py-1">
                            <div className="pl-4 md:pl-4 lg:pl-8">
                                <img src={WhatsAppIcon} className="w-[1.5rem] h-[1.5rem] md:w-[1.5rem] md:h-[1.5rem] lg:w-[1.8rem] lg:h-[1.8rem]" alt="" />
                            </div>
                            <p
                                className="text-white text-[13px] md:text-[14px] lg:text-[14px] whitespace-nowrap cursor-pointer"
                                onClick={() => window.open(Constants.kalyanWhatsappUrl(7550006035))}
                            >
                                +91 75500 06035
                            </p>
                            <div className="flex items-center gap-2">
                                <img className="w-[1.3rem] md:w-[1.5rem] lg:w-[1.5rem]" src={MailIcon} alt="" />
                                <p className="text-white text-[13px] md:text-[14px] lg:text-[14px]">
                                    <a href={`mailto:${Constants.helpLineMailId}`} target='_blank' rel='noreferrer'>{Constants.helpLineMailId}</a>
                                </p>
                            </div>
                        </div>
                        : isCommunitySite ?
                            <></>
                            : ""
                        // <div className='bg-[#D62128] text-white text-center py-1 text-[11px] lg:text-[13px] xl:text-[15px] sticky top-0 z-50'>
                        // From the Trusted Brand Kalyan Jewellers
                        // </div>
                    }


                    <div className={`${props.menu === "mobilebasic" ? "" : props.type === "mobileBasicHeader" ? "" : props.page != "basic" ? "justify-between md:space-x-3 lg:space-x-0 flex  mx-0 p-2 md:p-0 md:ml-[0.6rem] lg:ml-[2rem] lg:mr-[2rem] md:mr-[0.6rem] items-center"
                        : "place-content-center lg:justify-start md:justify-start  flex  mx-0 p-2 md:p-0 md:ml-[2rem] md:mr-[2rem] items-center"} ${props?.isFrom == "NRI" ? "md:max-h-[7rem] lg:max-h-[8rem]" : ""}`}>
                        {props.menu === "mobilebasic" ?
                            <div >
                                <a href="" className="flex items-center py-2 mx-[3rem]">
                                    <img src={AboutusLogo} className="h-12 md:h-[4rem] lg:h-[3.5rem]" alt="" />
                                </a>
                            </div>
                            : props.type === "mobileBasicHeader" ? ""
                                : <div className=' flex gap-3 md:gap-2 lg:gap-5'>
                                    <a href="" className="flex items-center">
                                        <img src={require('../../Images/KM-logo_2x.webp')} className="lg:h-[5.5rem] hidden md:hidden lg:block" width="205" height="100" alt="" />
                                        <img src={require('../../Images/KM-logo_2x.webp')} className="md:h-[4rem] md:block hidden lg:hidden" width="150" height="100" alt="" />
                                        <img src={require('../../Images/KM-logo_2x.webp')} className="h-12  md:hidden lg:hidden" height="100" alt="" />
                                    </a>


                                    <div className="flex  items-center  block">
                                        <img src={require("../../Images/kalyan_logo.svg").default} className='hidden lg:block' alt="" width="50" height="50" />
                                        <img src={require("../../Images/kalyan_logo.svg").default} className='hidden lg:hidden md:block' alt="" width="30" height="50" />
                                        <img src={require("../../Images/kalyan_logo.svg").default} className='md:hidden lg:hidden block' alt="" width="25" height="50" />

                                        <p className="text-[#D2951B] pl-[0.8rem]  text-[8px] md:text-[8px] lg:text-[18px] font-bold " >
                                            Trusted Brand                       <br />
                                            Kalyan Jewellers
                                        </p>

                                    </div>
                                </div>

                        }
                        {props.menu === "mobilebasic" ? "" : props.type === "mobileBasicHeader" ? "" :
                            <div className="flex md:space-x-3 lg:space-x-6 ">
                                <div className="hidden md:flex  ">
                                    {props?.page == "register" &&
                                        <div className={`absolute right-[26rem] lg:right-[26rem]  md:right-[9rem] ${props?.isFrom == "NRI" ? "pt-[.5rem]" : ""}`}><a onClick={() => {
                                            onFamilyFriendPopupClick();
                                        }} className='underline text-[#D10A11] md:text-[11px] text-[13px] lg:text-[13px] cursor-pointer'>Login as Family Member/ Friend</a></div>}

                                    {props.page == "register" && <LoginForm menu="login" type="registers" />}
                                </div>
                                {props.page != "basic" &&
                                    <>
                                        <div className="hidden md:flex space-x-3 md:pt-2 lg:pt-0">
                                            <div>
                                                <p className="text-[8px] md:text-[11px] lg:text-[14px]  flex justify-center text-[#575556] font-semibold md:pt-3 ">Download our App</p>
                                                <div className="flex space-x-2 md:space-x-3 md:mt-[0.3rem] lg:mt-0">
                                                    <a href={isNRIPage ? RouteSegments.staticLinkForAppDownload.androidNri : RouteSegments.staticLinkForAppDownload.android} target="_blank">
                                                        {/* <div className="flex md:h-[2rem] lg:h-[2.4rem] md:w-[5rem] lg:w-[8rem]  bg-black text-white p-[0.3rem] md:p-0 rounded-[5px] md:rounded-lg items-center justify-center">
                                               <div>
                                                   <svg className="w-[0.7rem] md:w-[1rem] lg:w-[1.5rem]" viewBox="30 336.7 120.9 129.2" width="30">
                                                       <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
                                                       <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
                                                       <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
                                                       <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
                                                   </svg>
                                               </div>
                                               <div>
                                                   <div className=" text-[8px] md:text-[8px] lg:text-[12px] text-start">GET IT ON</div>
                                                   <div className=" text-[9px] md:text-[9px] lg:text-[12px] font-semibold font-segeo -mt-1">Google Play</div>
                                               </div>
                                       
                                           </div> */}
                                                        <img className='w-[11rem] md:w-[8rem] lg:w-[9rem] xl:w-[8rem]' src={GPlayIcon} alt="" />
                                                    </a>
                                                    <a href={RouteSegments.staticLinkForAppDownload.ios} target="_blank">
                                                        {/* <div className="flex md:h-[2rem] lg:h-[2.4rem] md:w-[5rem] lg:w-[8rem]  bg-black text-white p-[0.3rem] md:p-0 rounded-[5px] md:rounded-lg  items-center justify-center">
                                               <div>
                                                   <svg className=" w-[0.7rem] md:w-[1rem] lg:w-[1.5rem]"  viewBox="0 0 384 512" width="30" >
                                                       <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                                                   </svg>
                                               </div>
                                               <div>
                                                   <div className=" text-[8px] md:text-[8px] lg:text-[12px]">Download on the</div>
                                                   <div className=" text-[9px] md:text-[9px] lg:text-[18px] font-semibold font-segeo -mt-1">App Store</div>
                                               </div>
                                           </div> */}
                                                        <img className='w-[11rem] md:w-[8rem] lg:w-[9rem] xl:w-[8rem]' src={AppStoreIcon} alt="" />
                                                    </a>
                                                </div>
                                                <p className="font-segeo text-[8px] md:text-[11px] lg:text-[14px] py-1 md:py-2 flex justify-center text-[#D10A11] font-semibold  pr-[2rem] md:pr-0 ">
                                                    <a onClick={() => onClickTrack(EventName.Home_page_Buy_Gold_Saving_Scheme_Online)} href={RouteSegments.staticLinkForGoldSavingSchemeOnline} target="_blank">
                                                        Buy Gold Saving Scheme Online
                                                    </a>
                                                </p>
                                            </div>
                                            <div className=" flex items-center justify-center md:mb-5 lg:mb-7">
                                                <div className=" mb-2 ">
                                                    <a href={RouteSegments.HELP_AND_FAQ()}
                                                        onClick={() => {
                                                            onClickTrack(EventName.HomePageHelpButtonClick)
                                                        }} className=" font-segeo font-normal text-[13px] text-[#7A7A7A]">
                                                        <img className=" h-[18px] w-[27px]  md:h-[40px] md:w-[40px] lg:h-[32px] lg:w-[40px]  items-center" src={HelpIcon} alt="" />
                                                        <span className="text-[#D10A11] text-[13px] font-semibold absolute lg:ml-[6px]">Help</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* mobile view */}
                                        <div className="flex  space-x-3 md:hidden">
                                            <div>
                                                <p className="text-[8px] md:text-[11px] lg:text-[14px]  flex justify-center text-[#575556] font-semibold md:pt-3 ">
                                                    Download our App</p>
                                                <div className="flex space-x-2 md:space-x-4">
                                                    <div className="flex      items-center justify-center">
                                                        <a href={RouteSegments.staticLinkForAppDownload.android} target="_blank">
                                                            <img className='w-[5rem]  ' src={GPlayIcon} alt="" width="50" height="50" />

                                                        </a>
                                                    </div>
                                                    <div className="flex     items-center justify-center">
                                                        <a href={RouteSegments.staticLinkForAppDownload.ios} target="_blank">
                                                            <img className='w-[5rem]' src={AppStoreIcon} alt="" width="50" height="50" />
                                                        </a>
                                                    </div>

                                                </div>
                                                <p className=" text-[8px] md:text-[11px] lg:text-[14px] py-1 md:py-2 flex justify-center text-[#D10A11] font-semibold   ">
                                                    <a href={RouteSegments.staticLinkForGoldSavingSchemeOnline} target="_blank">
                                                        Buy Gold Saving Scheme Online
                                                    </a>
                                                </p>
                                            </div>
                                            <div className=" flex items-center justify-center ">
                                                <div className=" mb-[0.6rem] md:mb-0 md:pl-[4px] lg:pl-[1rem] ">
                                                    <a href={RouteSegments.HELP_AND_FAQ()} className=" font-segeo font-normal text-[13px] text-[#7A7A7A]">
                                                        <img className=" h-[28px] w-[33px]  md:h-[40px] md:w-[30px] lg:h-[32px] lg:w-[40px]  items-center" src={HelpIcon} alt='' />
                                                        <span className="text-[#D10A11] text-[10px] font-semibold absolute mx-[5px] my-[3px]">Help</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                        }
                    </div>
                </header>
            </div>
        </>
    )
}
